<template>
  <div>
    <b-modal
      id="add-commission-modal"
      ref="add-commission-modal"
      centered
      hide-footer
    >
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="Name" label-for="commission_name">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="commission_name"
                  name="commission_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                  v-model="commission_form.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Currency" label-for="currency">
              <validation-provider
                #default="{ errors }"
                name="currency"
                rules="required"
              >
                <v-select
                  id="currency"
                  v-model="commission_form.currency"
                  clearable
                  :options="currencyCodes.data"
                  label="code"
                  :reduce="(item) => item.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Amount" label-for="amount">
              <validation-provider
                #default="{ errors }"
                name="amount"
                rules="required"
              >
                <b-form-input
                  id="amount"
                  :state="errors.length > 0 ? false : null"
                  name="amount"
                  type="number"
                  v-model="commission_form.amount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Percent" label-for="percent">
              <validation-provider
                #default="{ errors }"
                name="percent"
                rules="required"
              >
                <b-form-input
                  id="percent"
                  :state="errors.length > 0 ? false : null"
                  name="percent"
                  type="number"
                  v-model="commission_form.percent"
                  min="1"
                  max="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Document Url" label-for="document_url">
              <validation-provider
                #default="{ errors }"
                name="document_url"
                rules="required"
              >
                <b-form-input
                  id="document_url"
                  name="document_url"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Document Url"
                  v-model="commission_form.document_url"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Notes" label-for="notes">
              <validation-provider
                #default="{ errors }"
                name="notes"
                rules="required"
              >
                <b-form-textarea
                  id="notes"
                  v-model="commission_form.notes"
                  placeholder="Notes"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button
              variant="outline-primary"
              type="submit"
              @click.prevent="addCommission"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-card no-body class="p-2">
      <button class="btn btn-primary mb-2" style="width: max-content; "  v-b-modal.add-commission-modal >
            Add Commission
          </button>
      <h5 class="mb-1">Filters</h5>
      <!-- Table Filters -->
      <b-row cols="8">
        <b-col md="10">
          <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
            @input="onSearch" />
        </b-col>
        <b-col md="2" class="
            d-flex
            align-items-end
            justify-content-center
            mb-1 mb-md-0
            pr-2
          ">
          <button class="btn btn-primary mr-1 w-100" @click="getUniList()">
            Search
          </button>
          <button class="btn btn-primary w-100" @click="resetFilters">
            Reset
          </button>
        </b-col>

        <!-- Per Page -->
      </b-row>
    </b-card>
    <b-card class="mb-0">
      <!-- Student List -->
      <b-table id="CommissionsTable" ref="refStudentsListTable" class="position-relative" :items="fullCommissions"
        responsive :fields="tableColumns" primary-key="uc_id" show-empty empty-text="No matching records found"
        :per-page="pagination.perPage" :current-page="pagination.currentPage" selectable>
        <template #cell(uc_document_url)="data">
          <a :href="data.item.uc_document_url" target="_blank">View</a>
        </template>
        <template #cell(created_by)="data">
          {{ formatDate(data.item.created_by) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              {{ commissions.length }} entries
            </span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination aria-controls="CommissionsTable" v-model="pagination.currentPage"
              :total-rows="commissions.length" :per-page="pagination.perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import AccountsServices from "@/apiServices/AccountsServices";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import currencyCodes from "currency-codes";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
  },
  data() {
    return {
      university_id: null,
      commissions: [],
      fullCommissions: [],
      commission_form: {
        name: "",
        currency: "INR",
        amount: "",
        percent: null,
        document_url: "",
        notes: "",
        university_id: null,
      },
      filters: {
        search: "",
      },
      tableColumns: [
        { key: "uc_id", label: "Id", sortable: false },
        { key: "uc_name", label: "Name", sortable: false },
        { key: "uc_currency", label: "Currency", sortable: false },
        { key: "uc_amount", label: "Amount", sortable: false },
        { key: "uc_document_url", label: "Document", sortable: false },
        { key: "uc_notes", label: "Notes", sortable: false },
        { key: "created_by", label: "Date", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      currencyCodes,
    };
  },
  methods: {
    async addCommission() {
      this.formErrors = {}; // Clear previous errors
      const { name, currency, amount } = this.commission_form;
      if (!name) this.formErrors.name = "Name is required.";
      if (!currency) this.formErrors.currency = "Currency is required.";
      if (!amount) this.formErrors.amount = "Amount is required.";

      if (Object.keys(this.formErrors).length > 0) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Validation Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: Object.values(this.formErrors).join(" & "),
          },
        });
        return;
      }
      const response = await AccountsServices.saveCommission(
        this.commission_form
      );
      if (response.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Commission Added",
            icon: "Check",
            variant: "success",
            text: response.data.message,
          },
        });
        setTimeout(() => {
        this.fullCommissions = [...this.fullCommissions , response.data.data]
          this.closeModal();
        }, 2000);
      }
    },
    async getCommissions() {
      const response = await AccountsServices.getUniversityCommissions(
        this.university_id
      );
      this.fullCommissions = response.data.data
      this.commissions = response.data.data
    },
    onSearch() {
      this.fullCommissions = this.commissions.filter((uni, index) => {
        let uc_name = uni.uc_name;
        let text = this.filters.search;
        let text_search = true;

        if (text) {
          text_search = uc_name
            .toLowerCase()
            .includes(text.toLowerCase());
        }

        return text_search;
      });
    },
    closeModal() {
      this.$refs["add-commission-modal"].hide();
      this.commission_form = {
        name: "",
        currency: "INR",
        amount: "",
        document_url: "",
        notes: "",
        university_id:null,
        percent:null
      };
      this.formErrors = {};
    },
    resetFilters() {
      this.filters = {
        search: "",
      };

      this.getUniList();
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  beforeMount() {
    const { university_id } = this.$route.params;
    this.commission_form.university_id = university_id
    this.university_id = university_id;
    this.getCommissions();
    this.$store.dispatch("home/onGetCountries");
  },
};
</script>
